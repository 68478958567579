import React from 'react';
import './header.scss';
import { NavLink } from 'react-router-dom';
import ErrorBoundary from '../../utils/ErrorBoundary';
import MobileMenu from './mobile-menu';

export default function Header() {
  return (
    <div className={"header"}>
      <div className={"wordmark"}>Marco Cabrera II</div>
      <div className={"headerMenu"}>
        <span id="mobileMenu">
          <MobileMenu />
        </span>
        <ul id="standardMenu">
          <li>
            <ErrorBoundary>
            <NavLink className={({ isActive, isPending }) =>
              isPending ? "" : isActive ? "selected" : window.location.pathname === "/" ? "selected" : ""
            }
            to="/resume">Resume</NavLink>
            </ErrorBoundary>
          </li>
          <li>
            <ErrorBoundary>
              <NavLink className={({ isActive, isPending }) =>
                isPending ? "" : isActive ? "selected" : ""
              }
              to="/recommendations">Recommedations</NavLink>
            </ErrorBoundary>
          </li>
        </ul>
      </div>

    </div>
  );
}

      