import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteRight, faQuoteLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import { getCompany } from '../utils/common';
import recommendation from "../data/recommendation.json";
import company from "../data/company.json";
import './recommendations.scss';
import LinkedInLogo from "../img/In-2C-75px-R.png";
import ErrorBoundary from '../utils/ErrorBoundary';

export default function Recommendations( ) {
    const [selectedCompanyId, setSelectedCompanyId] = useState(0);

    const toggleCompanyId = (companyId:number) => {
        if ( companyId === selectedCompanyId ) {
            companyId = 0;
        }
        setSelectedCompanyId(companyId);
    };

    const getCompanyName = (id:number) => {
        const company = getCompany(id);
        return ( company !== ''  ) ? company.name : '';
    };

    const CompanyDetail = () => {
        const company = getCompany(selectedCompanyId);
        if ( selectedCompanyId !== 0 && company !== '' ) {
            return (
                <div className={"notifierPanel"}>
                    <h2><button className={"closeX"} onClick={() => toggleCompanyId(0)}><FontAwesomeIcon icon={faTimes} /></button>{company.name}</h2>
                    <p>{company.description}</p>
                    <div className={"instructions"}>Please note that the list of recommendations below has been filtered to show you only the recommendations from the noted company.</div>
                </div>
            );
        }
        return null;
    };

    const Company = (com:any) => {
        if ( com.showInRec === true ) {
            return <button className={"leftPanelButton" + (selectedCompanyId === com.id ? " selected" : "")} onClick={() => toggleCompanyId(com.id)} >{com.name.trim()}</button>;
        }
        return null;
    };

    const Recommendation = (rec:any) => {
        if ( selectedCompanyId === 0 || selectedCompanyId === rec.companyId ) {
            return (
                    <div className={"recommendation"} key={rec.id}>
                        <div className={"quote"}>
                            <div className={"actualQuote"}>
                                <div className={"icon"}>
                                    <FontAwesomeIcon icon={faQuoteLeft} />
                                </div>
                                <div className={"quoteText"}>
                                    {rec.recommendation.map((par:string, i:number) => {
                                        return (
                                            <p key={i} className={"rec"}>{par.trim()}</p>
                                        );
                                    })}
                                </div>
                                <div className={"icon"}>
                                    <FontAwesomeIcon icon={faQuoteRight} />
                                </div>
                            </div>
                            
                            <div className={"signature"}>
                                &mdash;&nbsp;{rec.name.trim()}
                                &nbsp;&mdash;&nbsp;{rec.title.trim()}
                                <br />{getCompanyName(rec.companyId)}
                            </div>
                        </div>
                    </div>
                );
            }
            return null;
        };

        return (
            <ErrorBoundary>
                <div className={"container"}>
                    <div className={"containerLeft"}>
                        <h2>Filter By</h2>
                        {company.map(com => {
                            return <Company key={com.id} {...com} />;
                        })}
                        <div className={"linkedIn"}>
                            View my profile at:<br />
                            <a href="https://www.linkedin.com/in/marccabrera/" className={"linkedInA"} target="_blank" rel="noopener noreferrer">
                            <img src={LinkedInLogo} alt="LinkedIn Logo" />
                            </a>
                        </div>
                    </div>
                    <div className={"containerRight"}>
                        <h1>Recommendations</h1>
                        <CompanyDetail />
                        {recommendation.map((rec) => {
                            return <Recommendation key={rec.id} {...rec} />
                        })}
                    </div>
                </div>
            </ErrorBoundary>
    );
}
