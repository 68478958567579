import React from 'react';
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes

} from "react-router-dom";
import Header from './layout/header/header';
import './scss/App.scss';

import ResumeDetail from './resume-detail/resume-detail';
import Recommendations from './recommendations/recommendations';

function App() {
  return (
    <BrowserRouter>
      <div>
        <Header />
          <Routes>
            <Route path="resume" element={<ResumeDetail />} />
            <Route path="recommendations" element={<Recommendations />} />
            <Route path="*" element={<ResumeDetail />} />
          </Routes>
        <Outlet />
      </div>
    </BrowserRouter>
  );
}

export default App;
