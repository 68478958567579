import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPrint } from '@fortawesome/free-solid-svg-icons'
import { displayDate, getCompany, getIntroduction } from '../utils/common';
import certifications from "../data/certifications.json";
import education from "../data/education.json";
import experience from "../data/experience.json";
import volunteer from "../data/volunteer.json";
import skills from "../data/skills.json";
import resumePDF from "../assets/CabreraMarcoResume.pdf";
import './resume-detail.scss';
import ErrorBoundary from '../utils/ErrorBoundary';

export default function ResumeDetail( ) {
    const [selectedSkillId, setSelectedSkillId] = useState(0);

    const toggleSkillId = (skillId:number) => {
        if ( skillId === selectedSkillId ) {
            skillId = 0;
        }
        setSelectedSkillId(skillId);
    };

    const Certification = (cert:any) => {
        return <div className={"certification"}>{cert.certification.trim()}</div>;
    };
    
    const Education = (ed:any) => {
        return (
            <div className={"education"}>
                <div>{ed.school} ~ {ed.year}</div>
                <div>{ed.degree}</div>
            </div>);
    };

    type iSkill = {
        id: number,
        name: string,
        years: number,
        "year-start": number,
        "year-end": number,
        description: string,
        clickable?: boolean,
        selected?: boolean
    };

    const Experience = (exp:any) => {
        const company = getCompany(exp.companyId);
        const companyName = company === '' ? '' : company.name;
        if ( selectedSkillId === 0 || exp === undefined || exp.skill === undefined|| exp.skill.indexOf(selectedSkillId) >= 0 ) {
            return (
                <div className={"experience"}>
                    <div className={"date"}>{displayDate(exp.startDate)}&mdash;{displayDate(exp.endDate)}</div>
                    <div className={"exp-header"}>{companyName}</div>
                    <div className={"title"}>{exp.title.join('/')}</div>
                    { exp.description !== undefined && exp.description !== '' &&
                        <div className={"description"}>{exp.description}</div>
                    }
                    { exp.project !== undefined && exp.project.length > 0 &&
                    <div className={"projects"}>
                        <strong>Project highlights</strong>
                        <ul>
                            {exp.project.map((proj:string, i:number) => {
                                return (
                                    <li key={i}>{proj}</li>
                                );
                            })}
                            
                        </ul>
                    </div>
                    }
                    { exp.skill !== undefined && exp.skill.length > 0 &&
                    <div className={"skill"}>
                        <div className="inline-skill">
                            {exp.skill.map((skill:number, i:number) => {
                                const sk: iSkill = getSkill(skill) as iSkill;
                                return (
                                    <Skill key={sk.id} {...sk} clickable={false} selected={selectedSkillId === sk.id} />
                                );
                            })}
                            
                        </div>
                    </div>
                    }
                    
                </div>
            );
        }
        return null;
    };

    const getSkill = (id:number) => {
        const skill = skills.filter(sk => sk.id === id);
        return ( skill !== undefined && skill.length > 0 ) ? skill[0] : '';
    };

    const Skill = (sk:iSkill) => {
        let classes = "leftPanelButton skillButton";
        if ( sk.selected === true ) {
            classes += " selected";
        }
        return <button className={classes} 
        onClick={() => sk.clickable !== false ? toggleSkillId(sk.id) : false} 
        >{sk.name.trim()}</button>;
    };

    const SkillDetail = () => {
        const skill: iSkill = getSkill(selectedSkillId) as iSkill;
        if ( selectedSkillId !== 0 ) {
            return (
                <div className={"notifierPanel"}>
                    <h2><button className={"closeX"} onClick={() => toggleSkillId(0)}><FontAwesomeIcon icon={faTimes} /></button>{skill.name}</h2>
                    <p>{skill.description}</p>
                    <div className={"instructions"}>Please note that the list of experience below has been filtered to show you only the positions that utilized this skill.</div>
                </div>
            );
        }
        return null;
    };

    const Volunteer = (vol:any) => {
        return (
            <div className={"volunteer"}>
                <div className={"company-header"}>{vol.company}</div>
                <ul>
                    {vol.position.map((v:string, i:number) => <li key={i}>{v}</li> )}
                </ul>
            </div>);
    };

    return (
        <ErrorBoundary>
            <div className={"container"}>
                <div className={"containerLeft"}>
                    <div className={"leftSection"}>
                        <h2>Skills</h2>
                        {skills.map(sk => {
                            return <Skill key={sk.id} {...sk} selected={selectedSkillId === sk.id} />;
                        })}
                    </div>
                    <div className={"leftSection"}>
                        <h2>Certifications</h2>
                        {certifications.map(cert => {
                            return <Certification key={cert.id} {...cert} />;
                        })}
                    </div>
                    <div className={"leftSection"}>
                        <h2>Volunteer</h2>
                        {volunteer.map((vol, i) => {
                            return <Volunteer key={i} {...vol} />;
                        })}
                    </div>
                    <div className={"leftSection"}>
                        <h2>Education</h2>
                        {education.map((ed, i) => {
                            return <Education key={i} {...ed} />;
                        })}
                    </div>
                </div>
                <div className={"containerRight"}>
                    <h1>
                        <div className={"resumeSubHeader"}>
                            <div className={"email"}>me@marcocabrera.com</div>
                            Traverse City, Michigan
                        </div>
                        Resume
                        <div className={"print-icon"}>
                            <a href={resumePDF} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faPrint} />
                            </a>
                        </div>
                        {/* <span class="printOnly">Marco Cabrera II</span> */}
                    </h1>
                    <div className={"conversationalSpeech"}>
                        {getIntroduction()}
                    </div>
                    <h2>Experience</h2>
                    <SkillDetail />
                    {experience.map((exp) => {
                        return <Experience key={`exp${exp.id}`} {...exp} />
                    })}
                </div>
            </div>
        </ErrorBoundary>
    );
}