// import React from 'react';
import company from "../data/company.json";
import skills from "../data/skills.json";
import intro from "../data/introduction.json";

export const getCompany = (id:number) => {
    const comp = company.filter(comp => comp.id === id);
    return ( comp !== undefined && comp.length > 0 ) ? comp[0] : '';
};

export const displayDate = (date: string) => {
    if ( date === "Present" || date.length === 4) {
        return date;
    }
    const dispDate = new Date(date);
    const monthName = getDisplayMonth(dispDate.getMonth(), false);
    return `${monthName} ${dispDate.getFullYear()}`;
};

export const getDisplayMonth = (month:number, full:boolean) => {
    let monthName = '';
    switch (month) {
        case 1:
            monthName = 'February';
            break;
        case 2:
            monthName = 'March';
            break;
        case 3:
            monthName = 'April';
            break;
        case 4:
            monthName = 'May';
            break;
        case 5:
            monthName = 'June';
            break;
        case 6:
            monthName = 'July';
            break;
        case 7:
            monthName = 'August';
            break;
        case 8:
            monthName = 'September';
            break;
        case 9:
            monthName = 'October';
            break;
        case 10:
            monthName = 'November';
            break;
        case 11:
            monthName = 'December';
            break;
        default:
            monthName = 'January';
            break;
    }
    return full ? monthName : monthName.substr(0,3);
};

export const getYearsOfExperience = () => {
    return (new Date().getFullYear() - 2000);
};

export const getIntroduction = () => {
    const years = getYearsOfExperience(); 
    return intro[0].introduction.replace('{years}', `${years}`);
};

export const getSkillNames = () => {
    return skills.map(skill => skill.name);
}

export const getSkillStartDiff = () => {
    const year = 2019;
    return skills.map(skill => (skill['year-start'] - year));
}

export const getSkillEndDiff = () => {
    return skills.map(skill => {
        const currentYear = new Date().getFullYear();
        const year = 2019;
        if (skill['year-end'] === 0) {
            return currentYear-year;
        }
        return skill['year-end'] - year;
    });
}