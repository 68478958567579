import React from 'react';
import { NavLink } from "react-router-dom";
import { slide as Menu } from 'react-burger-menu';
import './mobile-menu.scss';
import ErrorBoundary from '../../utils/ErrorBoundary';

export default class MobileMenu extends React.Component{
  showSettings (event:any) {
    event.preventDefault();
  }

  render () {
    return (
      <Menu right>
        <ErrorBoundary>
          <NavLink className={({ isActive, isPending }) =>
              isPending ? "" : isActive ? "selected" : ""
            }
            to="/resume">Resume</NavLink>
        </ErrorBoundary>
        <ErrorBoundary>
          <NavLink className={({ isActive, isPending }) =>
                isPending ? "" : isActive ? "selected" : ""
              }
              to="/recommendations">Recommedations</NavLink>
        </ErrorBoundary>
      </Menu>
    );
  }
}